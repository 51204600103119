import { useEffect } from "react";
import { useRouter } from "next/router";
import { useAtomValue, useSetAtom } from "jotai";
import useWindowDimensions from "../utils/useWindowDimensions";
import { mediumWidth } from "../utils/style";
import { isSidebarOpenAtom, isLoadedAtom } from "../model/atoms";
import { appNoteStore } from "../model/services";
import { trackEvent } from "../analytics/analyticsHandlers";
import { toParsedUrl } from "../search/SearchQuery";
import { RoutesOption } from "../routes";
import { reinitializeSearchQueryAtom } from "../search/useSearchQuery";
import { EditorWrapper } from "../components/EditorWrapper";

/** This component is purely a one-time side effect. It (1) creates a new note
 * and (2) redirects the app to open that new note in its own page so the user
 * can start editing it right away.
 */
export default function NewNotePage() {
  const router = useRouter();

  const { width } = useWindowDimensions();
  const setShowSidebar = useSetAtom(isSidebarOpenAtom);
  const wasInitialLoadDone = useAtomValue(isLoadedAtom);
  useEffect(() => {
    if (!wasInitialLoadDone) return;
    if (width < mediumWidth) setShowSidebar(false);

    (async () => {
      // create a note, navigate to its page, focused
      const [note] = appNoteStore.insert({});
      const newURL = new URL(RoutesOption.Home, window.location.href);
      const queryParams = toParsedUrl({ noteIdList: [note.id] });
      for (const [key, value] of Object.entries(queryParams)) {
        newURL.searchParams.set(key, value as string);
      }
      await router.replace(newURL);
      reinitializeSearchQueryAtom();
      trackEvent("create_note_as_page", note.id);
    })();
  }, [wasInitialLoadDone, router, width, setShowSidebar]);

  return (
    <EditorWrapper>
      <></>
    </EditorWrapper>
  );
}
